<template>
  <div>
    <v-tooltip bottom :color="color">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">{{ label }}</span>
      </template>
      <span>{{ hidden }}</span>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  name: "Tooltip",
  props: ["color", "label", "hidden"],
};
</script>
